import React from 'react'

const Terms = (props) => {
    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-10 mx-auto bg-white p-4" style={{ borderRadius: 4, marginBottom: 100 }}>
                    <h2 className="text-darkGreen ">Konkurrencebetingelser</h2>

                    <h5 className="text-darkGreen mt-4 mb-0">Weekendkonkurrence</h5>
                    <p>
                        Få en drømmestart i Varelotteriets forhandlerkonkurrence ved at sælge en masse nye lodder i løbet af weekenden fra fredag d. 20. til søndag den 22. december kl. 23:59. Den forhandler, der sælger flest nye lodder, vinder et Supergavekort på 500 kr. Ved pointsammenfald er det den forhandler, der først har fået solgt flest nye lodder, som kåres til vinder. Vinderen findes fredag den 3. januar.
                    </p>

                    <h5 className="text-darkGreen mt-4 mb-0">Ugens højdespringer</h5>
                    <p>
                        Ugens højdespringer kører hver uge fra mandag til søndag indtil kl. 23.59. Vinderen er den forhandler, der har solgt flest nye lodder. Ved pointsammenfald er det den forhandler, der først har fået solgt flest nye lodder, som kåres til vinder. Præmien er 500 kr. Der tildeles derudover også en andenpræmie på 300 kr. til forhandleren med næstflest solgte lodder. Vinderne udtrækkes hver mandag.
                        <br /><br />
                        Datoer for udtrækning af vindere er følgende: 3/1, 6/1, 13/1, 20/1, 27/1, 3/2, 10/2, 17/2, 24/2, 3/3 og 10/3. Der udloddes i alt 11 Supergavekort til 500 kr. til vinderne samt 11 Supergavekort til 300 kr. til andenpladserne.
                    </p>

                    <h5 className="text-darkGreen mt-4 mb-0">Chancen</h5>
                    <p>
                        Sælg minimum ét nyt lod 3 dage i træk. Under ’Min side’ kan du se, hvor mange dage du har solgt lodder i træk. Konkurrencen kører fra onsdag til onsdag, og vinderen kåres ved lodtrækning hver onsdag.
                        <br /><br />
                        Datoer for udtrækning af vinderne er følgende: 3/1, 8/1, 15/1, 22/1, 29/1, 5/2, 12/2, 19/2, 26/2, 5/3 og 12/3. Der udloddes i alt 11 Supergavekort til 600 kr.
                    </p>

                    <h5 className="text-darkGreen mt-4 mb-0">Speedy Gonzales</h5>
                    <p>
                        Skynd dit at sælge et nyt lod hver lørdag mellem kl. 12.00 – 12:10. Vinderen kåres ved lodtrækning hver mandag. Præmien er et Supergavekort er på 500 kr.
                        <br /><br />
                        Datoer for udtrækning af vindere er følgende: 3/1, 6/1, 13/1, 20/1, 27/1, 3/2, 10/2, 17/2, 24/2, 3/3 og 10/3. Der udloddes i alt 12 supergavekort til 500 kr.
                    </p>

                    <h5 className="text-darkGreen mt-4 mb-0">Danmarks bedste forhandlere</h5>
                    <p>
                        Sælg flest nye lodder i konkurrenceperioden (fra d. 20. december – 17. marts) og kom på top-10-listen over Danmarks bedste forhandlere. Vinderne bliver udtrukket den 18. marts, og præmierne er som følger:
                        <ol>
                            <li>Plads: Supergavekort på 3.000 kr.</li>
                            <li>Plads: Supergavekort på 1.500 kr.</li>
                            <li>Plads: Supergavekort på 1.200 kr.</li>
                            <li>Plads: Supergavekort på 1.000 kr.</li>
                            <li>Plads: Supergavekort på 700 kr.</li>
                            <li>Plads: Supergavekort på 600 kr.</li>
                            <li>Plads: Supergavekort på 500 kr.</li>
                            <li>Plads: Supergavekort på 500 kr.</li>
                            <li>Plads: Supergavekort på 500 kr.</li>
                            <li>Plads: Supergavekort på 500 kr.</li>
                        </ol>
                    </p>

                    <h5 className="text-darkGreen mt-4 mb-0">Min side</h5>
                    <p>
                        Et hurtigt overblik over din placering i den samlede stilling. På denne side får du også oplysninger om, hvor mange lodder du mangler at sælge for at rykke en placering op. Du kan også se hvor mange dage i træk, du har solgt minimum ét nyt lod.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Terms
